import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DtLanguageSelectComponent} from '@ui/modules/components/dt-fields/components/dt-language-select/dt-language-select.component';
import {DtTranslateModule} from '@ui/modules/pipes/dt-translate/dt-translate.module';
import {NzDividerModule} from 'ng-zorro-antd/divider';
import {NzDropDownDirective, NzDropdownMenuComponent} from 'ng-zorro-antd/dropdown';
import {NzIconModule} from 'ng-zorro-antd/icon';
import {NzMenuDirective} from 'ng-zorro-antd/menu';
import {NzSelectModule} from 'ng-zorro-antd/select';

import {DtInputComponent} from './components/dt-input/dt-input.component';

@NgModule({
  declarations: [DtInputComponent, DtLanguageSelectComponent],
  exports: [DtInputComponent, DtLanguageSelectComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NzIconModule,
    NzDividerModule,
    NzSelectModule,
    FormsModule,
    DtTranslateModule,
    NzDropdownMenuComponent,
    NzDropDownDirective,
    NzMenuDirective
  ]
})
export class DtFieldsModule {}
