import {registerLocaleData} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';
import en from '@angular/common/locales/en';
import ro from '@angular/common/locales/ro';
import {ErrorHandler, Injector, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {TranslateLoader} from '@ngx-translate/core';
import {DtLocationSelectModule} from '@ui/modules/components/dt-location-select/dt-location-select.module';
import {DtTranslateModule} from '@ui/modules/pipes/dt-translate/dt-translate.module';
import {DtTranslateBrowserLoader} from '@ui/modules/pipes/dt-translate/loaders/dt-translate-browser.loader';
import {en_US, NZ_I18N} from 'ng-zorro-antd/i18n';
import {NzModalModule} from 'ng-zorro-antd/modal';
import {NzNotificationModule} from 'ng-zorro-antd/notification';

import {environment} from '../environments/environment';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {GlobalErrorHandler} from './core/handlers/global-error.handler';
import {ErrorInterceptor} from './core/interceptors/error.interceptor';
import {SessionInterceptor} from './core/interceptors/session.interceptor';
import {UserEffects} from './core/store/effects/user.effects';
import {appReducers} from './core/store/reducers/app.reducers';
import {AlertsModule} from './shared/components/alerts/alerts.module';
import {DialogsModule} from './shared/components/dialogs/dialogs.module';

registerLocaleData(en);
registerLocaleData(ro);

export const APP_LANGUAGE_PATH = ['assets/i18n/', 'assets/i18n/footer-'];

export function TranslateBrowserLoaderFactory(httpClient: HttpClient) {
  return new DtTranslateBrowserLoader(httpClient, APP_LANGUAGE_PATH);
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    AlertsModule,
    BrowserModule.withServerTransition({appId: 'serverApp'}),
    AppRoutingModule,
    BrowserAnimationsModule,
    DialogsModule,
    DtTranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: TranslateBrowserLoaderFactory,
        deps: [HttpClient]
      }
    }),
    StoreModule.forRoot(appReducers, {
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false
      }
    }),
    EffectsModule.forRoot([UserEffects]),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production
    }),
    NzModalModule,
    NzNotificationModule,
    DtLocationSelectModule.forRoot(environment.MAP_API_KEY)
    // ServiceWorkerModule.register('ngsw-worker.js', {
    //   enabled: true,
    //   registrationStrategy: 'registerImmediately'
    // })
  ],
  providers: [
    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: 'environment',
      useValue: environment
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SessionInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    },
    {
      provide: NZ_I18N,
      useValue: en_US
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  static injector: Injector;

  constructor(injector: Injector) {
    AppModule.injector = injector;
  }
}
