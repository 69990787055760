import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {DtTranslateService} from '@ui/modules/pipes/dt-translate/services/dt-translate.service';

@Component({
  selector: 'dt-language-select',
  templateUrl: './dt-language-select.component.html',
  styleUrl: './dt-language-select.component.scss'
})
export class DtLanguageSelectComponent implements OnInit {
  @Output() changeLanguage = new EventEmitter<string>();

  systemLanguages = {
    en: {
      title: 'English',
      icon: 'assets/flags/uk.svg'
    },
    ro: {
      title: 'Română',
      icon: 'assets/flags/ro.svg'
    }
  };
  systemLanguage = this.systemLanguages.en;
  languagesDropdownVisible = false;

  constructor(private dtTranslateService: DtTranslateService) {}

  ngOnInit(): void {
    this.getSystemLanguage();
  }

  getSystemLanguage(): void {
    const langKey = this.dtTranslateService.getCurrentLanguage();
    this.systemLanguage = this.systemLanguages[langKey as keyof typeof this.systemLanguages];
  }

  changeSystemLanguage(lang: 'en' | 'ro'): void {
    this.languagesDropdownVisible = false;
    setTimeout(() => {
      this.dtTranslateService.setTranslations(lang);
      this.systemLanguage = this.systemLanguages[lang];
      this.changeLanguage.emit(lang);
    }, 300);
  }
}
