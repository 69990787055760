import {DOCUMENT} from '@angular/common';
import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';

export interface DtScriptConfig {
  id: string;
  type?: string;
  src?: string;
  content?: string;
  parent?: HTMLElement;
}

@Injectable({
  providedIn: 'root'
})
export class DtScriptsService {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  loadScript(config: DtScriptConfig): Observable<HTMLElement> {
    return new Observable((observer) => {
      const scriptAlreadyExists = !!this.document.getElementById(config.id);
      if (!scriptAlreadyExists) {
        const scriptParent = config.parent ?? this.document.head;
        const script = this.document.createElement('script');
        script.async = true;
        script.id = config.id;
        if (config.type) {
          script.type = config.type;
        }
        if (config.src) {
          script.src = config.src;
          script.onload = () => {
            observer.next(script);
            observer.complete();
          };
        }
        if (config.content) {
          script.innerHTML = config.content;
        }
        scriptParent.appendChild(script);
        if (!config.src) {
          observer.next(script);
          observer.complete();
        }
      } else {
        observer.error({error: 'script already exists'});
      }
    });
  }
}
