<div
  class="language-select"
  nz-dropdown
  nzTrigger="click"
  [nzPlacement]="'topCenter'"
  [nzDropdownMenu]="menu"
  [(nzVisible)]="languagesDropdownVisible">
  <img alt="Flag" [src]="systemLanguage.icon" />
  {{ systemLanguage.title }}
  <i nz-icon nzType="ng-zorro:arrow-down"></i>
</div>
<nz-dropdown-menu #menu="nzDropdownMenu">
  <ul class="language-select-menu" nz-menu>
    <li (click)="changeSystemLanguage('en')">
      <img src="assets/flags/uk.svg" alt="UK flag" />
      <span>English</span>
    </li>
    <li (click)="changeSystemLanguage('ro')">
      <img src="assets/flags/ro.svg" alt="RO flag" />
      <span>Română</span>
    </li>
  </ul>
</nz-dropdown-menu>
