import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {DtTranslateService} from '@ui/modules/pipes/dt-translate/services/dt-translate.service';
import {catchError, map, Observable, of} from 'rxjs';

import {AuthService} from '../../features/auth/services/auth.service';
import {User} from '../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class UserLoaderGuard {
  constructor(
    private dtTranslateService: DtTranslateService,
    private authService: AuthService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authService.authCheck().pipe(
      catchError((error) => {
        return of(null);
      }),
      map((user: User) => {
        if (user) {
          this.authService.setUser(user as User);
          this.dtTranslateService.predefinedLanguage = user.settings.language;
        }
        return true;
      })
    );
  }
}
