import {HttpClient} from '@angular/common/http';
import {TranslateLoader} from '@ngx-translate/core';
import {catchError, forkJoin, map, Observable, of, take} from 'rxjs';

export class DtTranslateBrowserLoader implements TranslateLoader {
  constructor(
    private http: HttpClient,
    private urls: Array<string>
  ) {}

  getTranslation(lang: string): Observable<any> {
    return new Observable((observer) => {
      const requests = [];
      for (const url of this.urls) {
        const langFileUrl = `${url}${lang}.json?cb=${new Date().getTime()}`;
        requests.push(
          this.http
            .get(langFileUrl, {
              responseType: 'json',
              params: {
                ignoreErrorWarning: true,
                ignoreSessionRedirect: true
              }
            })
            .pipe(
              catchError(() => {
                return of(null);
              })
            )
        );
      }
      forkJoin(requests)
        .pipe(
          map((results) => {
            results.reverse();

            const translationJson = {};

            results.forEach((fileContent) => {
              Object.assign(translationJson, fileContent);
            });

            return translationJson;
          }),
          take(1)
        )
        .subscribe((res) => {
          observer.next(res);
          observer.complete();
        });
    });
  }
}
