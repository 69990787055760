import {Injectable} from '@angular/core';
import {DtLabelsService} from '@ui/modules/directives/dt-labels/services/dt-labels.service';
import {DtTranslateService} from '@ui/modules/pipes/dt-translate/services/dt-translate.service';
import {map, Observable, tap} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DtTranslationsResolver {
  constructor(
    private dtTranslateService: DtTranslateService,
    private dtLabelsService: DtLabelsService
  ) {}

  resolve(): Observable<boolean> {
    let currentLanguage = 'en';
    if (this.dtLabelsService.isRomanianLabel) {
      currentLanguage = 'ro';
    }
    if (this.dtTranslateService.predefinedLanguage) {
      currentLanguage = this.dtTranslateService.predefinedLanguage;
    }
    return this.dtTranslateService.getTranslations(currentLanguage).pipe(
      tap(() => {
        this.dtTranslateService.setTranslations(currentLanguage);
      }),
      map(() => true)
    );
  }
}
